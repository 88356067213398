var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'flex flex-items-baseline gap-3': _vm.inlineView }},[_c('div',{staticClass:"info-block-key mr-2",class:{ 'info-block-key--inline': _vm.inlineView },style:({ 'font-size': _vm.fontSize })},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"info-block-value flex align-center",class:{
      'info-block-value--inline': _vm.inlineView,
      'flex-2': _vm.flexSecondColumnTwo,
    }},[(!_vm.loading && _vm.$slots.icon)?_c('div',{staticClass:"mr-2"},[_vm._t("icon")],2):_vm._e(),(_vm.loading)?_c('VueSkeletonLoader',{attrs:{"animation":"wave","color":'#bec3d8',"height":20,"radius":'2',"type":"rect"}}):_c('div',{staticClass:"mr-2",class:{ 'word-break': _vm.wordBreakValue, 'link-text': _vm.linkTextValue },on:{"click":function($event){return _vm.$emit('clickedValue')}}},[_vm._v(" "+_vm._s(_vm.value === 0 ? 0 : (_vm.value || '–'))+" "),(_vm.rate)?_c('div',{directives:[{name:"on-clickaway",rawName:"v-on-clickaway",value:(_vm.closeTooltip),expression:"closeTooltip"},{name:"tooltip",rawName:"v-tooltip",value:({
          content: ("" + (_vm.priceMessage(_vm.rateTime, _vm.rateTimestamp))),
          show: _vm.showTooltip,
          trigger: 'manual',
        }),expression:"{\n          content: `${priceMessage(rateTime, rateTimestamp)}`,\n          show: showTooltip,\n          trigger: 'manual',\n        }"}],staticClass:"price info-block-rate",on:{"mouseleave":function($event){_vm.showTooltip = false},"mouseover":function($event){_vm.showTooltip = true}}},[_vm._v(" "+_vm._s(_vm.rate)+" ")]):_vm._e()]),(_vm.withCopy && _vm.value)?_c('div',[_c('gl-menu-item',{staticClass:"sidebar__history-copy",attrs:{"icon":"copy","icon-height":24,"icon-width":24},on:{"click":function($event){return _vm.copy(_vm.value)}}})],1):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }