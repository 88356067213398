// vuex
import { mapGetters } from 'vuex'
import analyticsState from "@/store/modules/analytics/state"

// utils
import { formatDate } from '@/utils/format-date'
import { formatByPrice } from '@/utils/format-by-price'
import { formatAmount, txsAddPlus } from '@/utils/format-amount'
import { toComaSeparate } from '@/utils/formatNumber'
import { formatShare, roundShare } from '@/utils/sourcesFormatter'
import { findMinMaxFields } from '@/utils/model'
import { calculateRiskLevel } from '@/utils/redesign-report'

export default {
  data() {
    return {
      headerForTransactions: [
        'TYPE/TAG',
        'RISK LABEL', 
        'OWNER', 
        'AMOUNT', 
        'PRICE', 
        'EXPOSURE', 
        'ROOT TX', 
        'LEAF TX', 
        'DEPTH', 
        'DATE & TIME',
      ],
      headerForTransactionsSwaps: [
        'SWAP DEPOSIT AMOUNT', 
        'SWAP DEPOSIT PRICE', 
        'SWAP WITHDRAW AMOUNT', 
        'SWAP WITHDRAW PRICE'
      ],
      csvData: {
        csvHeader: {
          type: 'key-value',
          title: 'This report is generated using Satoshi Eye \n proprietary technology research \n capabilities and community reports.',
          data: {},
        },
        csvMainData: {
          type: 'key-value',
          title: 'ADDRESS INFORMATION',
          data: {},
        },
        csvSofEvaluated: {
          type: 'key-value',
          data: {}
        },
        csvSofGroupedBy: {
          type: 'table',
          header: ['TYPE', 'AMOUNT', 'SHARE'],
          data: [],
        },
        csvUofEvaluated: {
          type: 'key-value',
          data: {}
        },
        csvUofGroupedBy: {
          type: 'table',
          header: ['TYPE', 'AMOUNT', 'SHARE'],
          data: [],
        },
        csvSofTransactions: {
          type: 'table',
          title: 'SOURCE OF FUNDS TRANSACTIONS',
          header: [],
          data: [],
        },
        csvUofTransactions: {
          type: 'table',
          title: 'USE OF FUNDS TRANSACTIONS',
          header: [],
          data: [],
        }
      }
    }
  },
  computed: {
    ...mapGetters('report', ['getReportInfo', 'getActiveToken', 'getActiveType', 'getSofExposureHeader', 'getUofExposureHeader', 'getSofExposure', 'getUofExposure', 'getSofTransactions', 'getUofTransactions', 'getSofCurrencies', 'getUofCurrencies']),
  },
  methods: {
    dateAndTimeOfSaveReport() {
      return formatDate(new Date().toISOString(), 'dd.MM.yyyy_HH-mm-ss')
    },
    mapTransactions(transactions) {
      return transactions.map(obj => {
        const baseArray = [
          obj.funds.type || '–',
          calculateRiskLevel(obj.funds.score),
          obj.owner,
          formatAmount(obj.amount),
          formatByPrice(obj.amountCur),
          formatShare(roundShare(obj.share)),
          obj.directTx,
          obj.tx_hash || 'Multiple',
          findMinMaxFields(obj.depth),
          obj.timestamp ? formatDate(new Date((typeof obj.timestamp === 'object' ? obj.timestamp.minimum : obj.timestamp) * 1000), 'dd.MM.yyyy HH:mm') : '–',
        ]

        if(analyticsState.coinData.family.toUpperCase() != 'BTC') {
          baseArray.push(
            this.generateSwapAmount(obj.inputAmounts),
            this.generateSwapPrice(obj.inputAmounts),
            this.generateSwapAmount(obj.outputAmounts),
            this.generateSwapPrice(obj.outputAmounts),
          )
        }

        return baseArray
      })
    },
    generateSwapAmount(data) {
      if(data) {
        let objWithCurrency = data.find(item => 'currency' in item)

        if (objWithCurrency) {
          return formatAmount(objWithCurrency.amount, {
              amountCoin: this.getSofCurrencies[objWithCurrency.currency]?.currency || this.getUofCurrencies[objWithCurrency.currency]?.currency,
              amountDecimals: this.getSofCurrencies[objWithCurrency.currency]?.decimals || this.getUofCurrencies[objWithCurrency.currency]?.decimals,
              comaSeparate: true,
            })
        } else {
          return formatAmount(data[0].amount, {
              amountCoin: analyticsState.coinData.family,
              amountDecimals: analyticsState.coinData.decimals,
              comaSeparate: true,
            })
        }
      }
    },
    generateSwapPrice(data) {
      if(data) {
        let objWithCurrency = data.find(item => 'currency' in item)

        if (objWithCurrency) {
          return formatByPrice(objWithCurrency.amountCur)
        } else {
          return formatByPrice(data[0].amountCur)
        }
      }
    },
    generateDataForReport() {
      this.csvData.csvHeader.data = {}
      this.csvData.csvMainData.data = {}
      this.csvData.csvSofEvaluated.data = {}
      this.csvData.csvUofEvaluated.data = {}
      this.csvData.csvSofGroupedBy.data = []
      this.csvData.csvUofGroupedBy.data = []
      this.csvData.csvSofTransactions.data = []
      this.csvData.csvUofTransactions.data = []

      if(analyticsState.coinData.family.toUpperCase() != 'BTC') {
        this.headerForTransactions.push(...this.headerForTransactionsSwaps)
      }

      this.csvData.csvSofGroupedBy.title = `SOURCE OF FUNDS GROUPED BY ${this.getActiveType == 'owners' ? 'OWNERS' : 'TYPE'}`
      this.csvData.csvUofGroupedBy.title = `USE OF FUNDS GROUPED BY ${this.getActiveType == 'owners' ? 'OWNERS' : 'TYPE'}`
      this.csvData.csvSofGroupedBy.header[0] = `${this.getActiveType == 'owners' ? 'OWNERS' : 'TYPE'}`
      this.csvData.csvUofGroupedBy.header[0] = `${this.getActiveType == 'owners' ? 'OWNERS' : 'TYPE'}`
      this.csvData.csvSofTransactions.header = this.headerForTransactions
      this.csvData.csvUofTransactions.header = this.headerForTransactions

      this.csvData.csvHeader.data.REPORT_GENERATED_AT = this.getReportInfo.createdAt ? formatDate(new Date(this.getReportInfo.createdAt), 'dd.MM.yyyy HH:mm') : "–"
      this.csvData.csvMainData.data.WALLET_ADDRESS = this.getReportInfo.walletAddress
      if (this.getActiveToken.address) {
        this.csvData.csvMainData.data.TOKEN_ADDRESS = this.getActiveToken.address
      }
      this.csvData.csvMainData.data.BLOCKCHAIN = analyticsState.coinData.family.toUpperCase()
      if(this.getActiveToken.symbol.toUpperCase() != 'BTC') {
        this.csvData.csvMainData.data.TOKEN = this.getActiveToken.symbol.toUpperCase() || analyticsState.coinData.family.toUpperCase()
      }
      this.csvData.csvMainData.data.OWNER = this.getReportInfo.owner || "Not identified"
      if(this.getReportInfo.tags) {
        this.csvData.csvMainData.data.TAGS = this.getReportInfo.tags
      }
      this.csvData.csvMainData.data.balanceDivider = 'divider'
      this.csvData.csvMainData.data.BALANCE = formatAmount(this.getReportInfo.balance, {isRaw: false})
      if(this.getReportInfo.totalSent) {
        this.csvData.csvMainData.data.TOTAL_SENT = formatAmount(this.getReportInfo.totalSent)
        this.csvData.csvMainData.data.TOTAL_SENT_PRICE = formatByPrice(this.getReportInfo.totalSentPrice)
      }
      if(this.getReportInfo.totalReceived) {
        this.csvData.csvMainData.data.TOTAL_RECEIVED = formatAmount(this.getReportInfo.totalReceived)
        this.csvData.csvMainData.data.TOTAL_RECEIVED_PRICE = formatByPrice(this.getReportInfo.totalReceivedPrice)
      }
      this.csvData.csvMainData.data.TOTAL_NUMBER_OF_TXS = toComaSeparate(txsAddPlus(this.getReportInfo.numberOfTxs, 2000002))
      this.csvData.csvMainData.data.TOTAL_TX_SENT = toComaSeparate(txsAddPlus(this.getReportInfo.totalTxSent, 1000001))
      this.csvData.csvMainData.data.TOTAL_TX_RECEIVED = toComaSeparate(txsAddPlus(this.getReportInfo.totalTxReceived, 1000001))
      if(this.getReportInfo.firstSeen) {
        this.csvData.csvMainData.data.FIRST_SEEN = formatDate(new Date((typeof this.getReportInfo.firstSeen === 'object' ? this.getReportInfo.firstSeen.minimum : this.getReportInfo.firstSeen) * 1000), 'dd.MM.yyyy HH:mm')
      }
      if(this.getReportInfo.lastSeen) {
        this.csvData.csvMainData.data.LAST_SEEN = formatDate(new Date((typeof this.getReportInfo.lastSeen === 'object' ? this.getReportInfo.lastSeen.minimum : this.getReportInfo.lastSeen) * 1000), 'dd.MM.yyyy HH:mm')
      }
      if(this.getReportInfo.riskScore || this.getReportInfo.riskScore == 0) {
        this.csvData.csvMainData.data.riskDivider = 'divider'
        this.csvData.csvMainData.data.RISK_SCORE = this.getReportInfo.riskScore
        this.csvData.csvMainData.data.RISK_RATING = calculateRiskLevel(this.getReportInfo.riskScore)
      }

      if(this.getSofExposureHeader.transactions) {
        this.csvData.csvSofEvaluated.data.EVALUATED_TXS = this.getSofExposureHeader.transactions
        this.csvData.csvSofEvaluated.data.EVALUATED_AMOUNT = this.getSofExposureHeader.amount || "-"
        this.csvData.csvSofEvaluated.data.EVALUATED_PRICE = this.getSofExposureHeader.price || "-"
      }

      if(this.getSofExposure.length) {
        this.csvData.csvSofGroupedBy.data = this.getSofExposure.map(obj => [
          obj.name,
          formatAmount(obj.amount),
          formatShare(roundShare(obj.share)),
        ])
      }

      if(this.getUofExposureHeader.transactions) {
        this.csvData.csvUofEvaluated.data.EVALUATED_TXS = this.getUofExposureHeader.transactions
        this.csvData.csvUofEvaluated.data.EVALUATED_AMOUNT = this.getUofExposureHeader.amount || "-"
        this.csvData.csvUofEvaluated.data.EVALUATED_PRICE = this.getUofExposureHeader.price || "-"
      }

      if(this.getUofExposure.length) {
        this.csvData.csvUofGroupedBy.data = this.getUofExposure.map(obj => [
          obj.name,
          formatAmount(obj.amount),
          formatShare(roundShare(obj.share)),
        ])
      }

      if(this.getSofTransactions.length) {
        this.csvData.csvSofTransactions.data = this.mapTransactions(this.getSofTransactions)
      }
      if(this.getUofTransactions.length) {
        this.csvData.csvUofTransactions.data = this.mapTransactions(this.getUofTransactions)
      }

      this.exportToCsv()
    },
    exportToCsv() {
      let csvContent = ''

      Object.keys(this.csvData).forEach((key, index, keys) => {
        const section = this.csvData[key]
        
        const hasData = section.type === 'key-value'
          ? Object.keys(section.data).length > 0
          : section.data.length > 0
      
        if (hasData) {
          if (section.title) {
            csvContent += section.title + '\n'
          }
          
          if (section.type === 'key-value') {
            csvContent += Object.entries(section.data)
              .map(([key, value]) => {
                if (value === 'divider') {
                  return 
                } else {
                  return `${key.replaceAll('_', ' ')};"${value}"`
                }
              })
              .join('\n') + '\n'
          } else if (section.type === 'table') {
            if (section.header) {
              csvContent += section.header.join(';') + '\n'
            }
            csvContent += section.data.map(row => row.map(item => `"${item || ''}"`).join(';')).join('\n') + '\n'
          }
      
          if (index < keys.length - 1) {
            csvContent += '\n\n'
          }
        }
      })

      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' })
      const link = document.createElement('a')
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob)
        link.setAttribute('href', url)
        link.setAttribute('download', `satoshieye_report_${this.dateAndTimeOfSaveReport()}.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    }
  }
}