<template>
  <div class="risk-exposure__blur">
    <template v-if="type == 'transactions'">
      <div class="risk-exposure__blur-transactions-background" />
    </template>
    <template v-else>
      <div 
        class="risk-exposure__blur-sof-uof-background" 
        :class="{ 'risk-exposure__blur-sof-uof-background--flipped' : type == 'uof' }"
      >
        <div class="risk-exposure__blur-sof-uof-background-left" />
        <div class="risk-exposure__blur-sof-uof-background-right" />
      </div>
    </template>
    <template v-if="loading">
      <div class="risk-exposure__blur-loader">
        <GlLoader progress-spinner />
      </div>
    </template>
    <template v-else>
      <div class="risk-exposure__blur-buttons">
        <div class="risk-exposure__blur-buttons-header">
          {{ textForButtonsHeader }}
        </div>
        <button
          v-if="visibilityForBlueButton"
          class="risk-exposure__blur-button risk-exposure__blur-button--blue"
          @click="clickOnBlueButton"
        >
          <span>{{ textForBlueButton }}</span>
          <ButtonLookupIcon />
        </button>
        <button
          v-if="visibilityForRedButton"
          class="risk-exposure__blur-button risk-exposure__blur-button--red"
          @click="clickOnRedButton"
        >
          <span>{{ textForRedButton }}</span>
          <ButtonLookupIcon />
        </button>
      </div>
    </template>
  </div>
</template>

<script>
// vuex 
import { mapGetters, mapMutations } from 'vuex'

// components
import GlLoader from '@/components/gl-loader'
import leftImage from '@/assets/images/exposure-blur-left.png'
import rightImage from '@/assets/images/exposure-blur-right.png'
import ButtonLookupIcon from './risk-exposure-components/svg/ButtonLookupIcon'

export default {
  components: {
    ButtonLookupIcon,
    GlLoader,
  },
  props: {
    type: {
      type: String,
      default: 'sof'
    },
    transactionsType: {
      type: String,
      default: 'sof'
    },
    loading: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveType', 'getActiveFunds', 'getPurchasedInfo', 'getCurrentPrices']),
    computedLeftImageSrc() {
      return this.type == 'sof' ? leftImage : rightImage
    },
    computedRightImageSrc() {
      return this.type == 'sof' ? rightImage : leftImage
    },
    textForButtonsHeader() {
      if(this.type == 'sof') {
        return `Source of Funds`
      } else if(this.type == 'uof') {
        return `Use of Funds`
      } else {
        return `Transactions`
      }
    },
    visibilityForBlueButton() {
      if(this.type == 'transactions') {
        return this.transactionsType == 'sof'
      } else {
        return this.getActiveType == 'riskLabels'
      }
    },
    visibilityForRedButton() {
      if(this.type == 'transactions') {
        return this.transactionsType == 'uof'
      } else {
        return this.getActiveType == 'owners'
      }
    },
    textForBlueButton() {
      if(this.type == 'transactions') {
        return `By Source of Funds for ${this.getCurrentPrices.sof_transactions}`
      } else {
        return `By Risk Labels for ${this.type == 'sof' ? this.getCurrentPrices.sof_type : this.getCurrentPrices.uof_type}`
      }
    },
    textForRedButton() {
      if(this.type == 'transactions') {
        return `By Use of Funds for ${this.getCurrentPrices.uof_transactions}`
      } else {
        return `By Owners for ${this.type == 'sof' ? this.getCurrentPrices.sof_entity : this.getCurrentPrices.uof_entity}`
      }
    }
  },
  mounted() {
    if(this.type == 'sof') {
      this.SET_SOF_EXPOSURE([])
      this.SET_SOF_EXPOSURE_HEADER({})
      this.SET_SOF_TRANSACTIONS([])
      this.SET_SOF_CURRENCIES({})
    } else if(this.type == 'uof') {
      this.SET_UOF_EXPOSURE([])
      this.SET_UOF_EXPOSURE_HEADER({})
      this.SET_UOF_TRANSACTIONS([])
      this.SET_UOF_CURRENCIES({})
    }
  },
  methods: {
    ...mapMutations('report', ['SET_SOF_EXPOSURE', 'SET_UOF_EXPOSURE', 'SET_SOF_EXPOSURE_HEADER', 'SET_UOF_EXPOSURE_HEADER', 'SET_SOF_TRANSACTIONS', 'SET_UOF_TRANSACTIONS', 'SET_SOF_CURRENCIES', 'SET_UOF_CURRENCIES']),
    clickOnBlueButton() {
      this.$emit('clickNewPurchaseButton', {
        reportPartName: this.type == 'transactions' ? 'sof_transactions' : `${this.type}_type`,
        price: this.type == 'transactions' ? this.getCurrentPrices.sof_transactions : this.type == 'sof' ? this.getCurrentPrices.sof_type : this.getCurrentPrices.uof_type
      })
    },
    clickOnRedButton() {
      this.$emit('clickNewPurchaseButton', {
        reportPartName: this.type == 'transactions' ? 'uof_transactions' : `${this.type}_entity`,
        price: this.type == 'transactions' ? this.getCurrentPrices.uof_transactions : this.type == 'sof' ? this.getCurrentPrices.sof_entity : this.getCurrentPrices.uof_entity
      })
    },
  },
}
</script>

<style scoped>
.risk-exposure__blur {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 400px;
  position: relative;
}

/* background images */
.risk-exposure__blur-sof-uof-background {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.risk-exposure__blur-sof-uof-background-left,
.risk-exposure__blur-sof-uof-background-right {
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.risk-exposure__blur-sof-uof-background-left {
  background-image: url('~@/assets/images/exposure-blur-left.png');
  background-position: center left;
}
.risk-exposure__blur-sof-uof-background-right {
  background-image: url('~@/assets/images/exposure-blur-right.png');
  background-position: center right;
}
.risk-exposure__blur-sof-uof-background--flipped {
  transform: scaleX(-1);
}
.risk-exposure__blur-transactions-background {
  width: 100%;
  height: 100%;
  background-image: url('~@/assets/svg/background/report/lock-full-section.svg');
  background-position: center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

/* buttons */
.risk-exposure__blur-buttons {
  position: relative;
  z-index: 3;
}
.risk-exposure__blur-buttons-header {
  margin-bottom: 32px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1;
  color: var(--dark);
  text-align: center;
}
.risk-exposure__blur-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  height: 50px;
  min-width: 248px;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 0px 15px 0px #0E0B3D0D;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  color: #ffffff;
  cursor: pointer;
}
.risk-exposure__blur-button svg {
  margin-bottom: 4px;
}
.risk-exposure__blur-button + .risk-exposure__blur-button {
  margin-top: 16px;
}
.risk-exposure__blur-button--blue {
  background-color: #194BFB;
}
.risk-exposure__blur-button--red {
  background-color: #F1104E;
}
</style>
