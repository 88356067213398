<template>
  <div class="dd-sort">
    <div
      class="dd-sort__view"
      :class="{'dd-sort__view--open' : isOpen}"
      @click="sortOpenClose"
    >
      {{ activeSortText }}
      <DropDownArrowIcon />
    </div>
    <transition name="fade">
      <div
        v-if="isOpen"
        v-click-outside="outsideClick"
        class="dd-sort__dropdown"
      >
        <div
          v-for="item in optionsForSort"
          :key="item.value"
          class="dd-sort__dropdown-item"
          :class="{ 'dd-sort__dropdown-item--active' : item.isActive }"
          @click="sortChange(item)"
        >
          <div class="dd-sort__dropdown-item-icon">
            <DropDownCheckIcon />
          </div>
          <div class="dd-sort__dropdown-item-text">
            {{ item.text }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DropDownArrowIcon from './svg/DropDownArrowIcon'
import DropDownCheckIcon from './svg/DropDownCheckIcon'

export default {
  components: {
    DropDownCheckIcon,
    DropDownArrowIcon,
  },
  data() {
    return {
      optionsForSort: [
        {
          text: 'Highest amount',
          value: 'hamount',
          isActive: false,
        },
        {
          text: 'Lowest amount',
          value: 'lamount',
          isActive: false,
        },
        {
          text: 'Highest risk',
          value: 'hrisk',
          isActive: false,
        },
        {
          text: 'Lowest risk',
          value: 'lrisk',
          isActive: false,
        },
      ],
      isOpen: false,
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveSort']),
    activeSortValue() {
      const activeOption = this.optionsForSort.find(option => option.isActive)
      return activeOption ? activeOption.value : null
    },
    activeSortText() {
      return this.optionsForSort.find(item => item.value == this.activeSortValue)?.text
    }
  },
  mounted() {
    if(this.getActiveSort) {
      this.sortChange(this.optionsForSort.find(item => item.value == this.getActiveSort))
    } else {
      this.sortChange(this.optionsForSort[0])
    }
  },
  methods: {
    sortOpenClose() {
      this.isOpen = !this.isOpen
    },
    outsideClick() {
      this.isOpen = false
    },
    sortChange(item) {
      if(!item.isActive) {
        const activeItem = this.optionsForSort.find(item => item.isActive);
        if (activeItem) {
          activeItem.isActive = false
        }
        item.isActive = true
        this.isOpen && this.sortOpenClose()
        this.$emit('sortChange', this.activeSortValue)
      }
    }
  }
}
</script>

<style scoped>
.dd-sort {
  height: 29px;
  padding: 0 10px;
  background-color: var(--pale-blue);
  border-radius: 8px;
  font-size: 14px;
  line-height: 1;
  position: relative;
}
.dd-sort__view {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 16px;
  cursor: pointer;
  position: relative;
}
.dd-sort__view svg {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translate(0, -50%);
  transition: transform 0.3s ease;
}
.dd-sort__view--open svg {
  transform: translate(0, -50%) rotate(180deg);
}
.dd-sort__dropdown {
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  padding: 10px;
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: 0px 0px 16px 0px rgba(9, 21, 64, 0.1);
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  z-index: 10;
}
.dd-sort__dropdown-item {
  display: flex;
  align-items: center;
  gap: 4px;
  user-select: none;
  cursor: pointer;
}
.dd-sort__dropdown-item + .dd-sort__dropdown-item {
  margin-top: 12px;
}
.dd-sort__dropdown-item-text {
  white-space: nowrap;
}
.dd-sort__dropdown-item-icon {
  overflow: hidden;
}
.dd-sort__dropdown-item-icon svg {
  opacity: 0;
}
.dd-sort__dropdown-item--active {
  cursor: default;
}
.dd-sort__dropdown-item--active .dd-sort__dropdown-item-icon svg {
  opacity: 1;
}
</style>