<template>
  <div
    ref="sourceTableContainer"
    class="source-table-wrapper"
  >
    <div class="source-table-head-wrapper">
      <div class="flex-1">
        <div class="light-grey-59-text uppercase fs-12 mb-2">
          Balance
        </div>
        <div class="fs-14 bold">
          {{ dataForBalance }}
        </div>
        <div
          v-if="activeSourcesFormatter.amountCur"
          class="price fs-12"
        >
          <span
            v-tooltip="{
              content: `${priceMessage('historical')}`,
              show: hoveredHeadPriceTooltip,
              trigger: 'manual',
              boundariesElement: sourceTableContainer,
            }"
            @mouseleave="hoveredHeadPriceTooltip = false"
            @mouseover="hoveredHeadPriceTooltip = true"
          >{{ formatByPrice(activeSourcesFormatter.amountCur) }}
          </span>
        </div>
      </div>
      <div class="flex-1">
        <div class="light-grey-59-text uppercase fs-12 mb-2">
          Exposure
        </div>
        <div class="fs-14 bold">
          {{ formatShare(roundShare(numberOfExposureShare)) }}
        </div>
      </div>
      <div class="flex-1">
        <div class="light-grey-59-text uppercase fs-12 mb-2">
          Number of Txs
        </div>
        <div class="fs-14 bold">
          {{ numberOfTxs }}
        </div>
      </div>
    </div>
    <DataTable
      ref="sourcesTable"
      class="sources-table p-table-groupped p-table-nowrap-data"
      data-key="id"
      :expanded-rows.sync="expandedRows"
      :paginator="activeSourceListData.length > perPage"
      responsive-layout="scroll"
      :rows="perPage"
      :scrollable="true"
      striped-rows
      :value="activeSourceListData"
      @page="onPage($event)"
    >
      <Column
        field="funds.score"
        header="Risk Label"
        sortable
      >
        <template #body="slotProps">
          <BadgeInfo
            :text="slotProps.data.funds.type"
            :value="slotProps.data.funds.score"
          />
        </template>
      </Column>
      <Column
        field="owner"
        header="Owner"
      >
        <template #body="slotProps">
          <div class="bold">
            {{ slotProps.data.owner || '–' }}
          </div>
        </template>
      </Column>
      <Column
        field="amount"
        header="Amount"
        sortable
      >
        <template #body="slotProps">
          <div class="bold fullwidth">
            <div class="mb-1">
              {{ formatAmount(slotProps.data.amount) }}
            </div>
            <div
              v-if="slotProps.data.amount"
              v-tooltip="{
                content: `${priceMessage(
                  'historical',
                  slotProps.data.amountCurTimestamp,
                )}`,
                show: hoveredPriceTooltipIndex === slotProps.index,
                trigger: 'manual',
                boundariesElement: sourceTableContainer,
              }"
              class="price fs-10"
              @mouseleave="hoveredPriceTooltipIndex = null"
              @mouseover="hoveredPriceTooltipIndex = slotProps.index"
            >
              {{ formatByPrice(slotProps.data.amountCur) }}
            </div>
          </div>
        </template>
      </Column>
      <Column
        :field="getActiveFunds == 'full' ? 'fullExposureShare' : 'share'"
        header="Exposure"
        sortable
      >
        <template #body="slotProps">
          <div class="bold">
            {{ formatShare(roundShare(getActiveFunds == 'full' ? slotProps.data.fullExposureShare : slotProps.data.share)) }}
          </div>
        </template>
      </Column>
      <Column
        field="directTx"
        header="Root Tx"
      >
        <template #header>
          <div class="source-table-header-info">
            <GlPopoverHint
              v-model="hoveredTooltipRoot"
              :boundaries-element="sourceTableContainer"
              head-text="Root Tx"
            >
              <template #content-body>
                <div class="source-table-popover-content-wrapper">
                  <div class="fs-12 mb-2">
                    "Root Tx" shows direct transactions of funds from wallet, marking the point where funds first enter the wallet.
                  </div>
                  <div class="source-table-popover-img-wrapper">
                    <img src="~@/assets/images/hints/rootTx-hint.png">
                  </div>
                </div>
              </template>
            </GlPopoverHint>
          </div>
        </template>
        <template #body="slotProps">
          <div class="flex align-center bold fullwidth">
            <div
              v-tooltip.focus="`${slotProps.data.directTx}`"
              class="link fullwidth"
              @click="openLink(slotProps.data.directTx, coinType, 'tx')"
            >
              {{ trancateString(slotProps.data.directTx, 8) || '–' }}
            </div>
          </div>
        </template>
      </Column>
      <Column
        field="tx_hash"
        header="Leaf Tx"
      >
        <template #header>
          <div class="source-table-header-info">
            <GlPopoverHint
              v-model="hoveredTooltipLeaf"
              :boundaries-element="sourceTableContainer"
              head-text="Leaf Tx"
            >
              <template #content-body>
                <div class="source-table-popover-content-wrapper">
                  <div class="fs-12 mb-2">
                    "Leaf Tx" identifies the last transaction before funds reach the wallet, helping to trace the origin of the money.
                  </div>
                  <div class="source-table-popover-img-wrapper">
                    <img src="~@/assets/images/hints/leafTx-hint.png">
                  </div>
                </div>
              </template>
            </GlPopoverHint>
          </div>
        </template>
        <template #body="slotProps">
          <div
            v-if="checkMultiple(slotProps.data.tx_hash)"
            class="bold"
          >
            Multiple
          </div>
          <div
            v-else
            class="flex align-center bold fullwidth"
          >
            <div
              v-tooltip.focus="`${slotProps.data.tx_hash}`"
              class="link fullwidth"
              @click="openLink(slotProps.data.tx_hash, coinType, 'tx')"
            >
              {{ trancateString(slotProps.data.tx_hash, 8) || '–' }}
            </div>
          </div>
        </template>
      </Column>
      <Column
        field="depthSortValue"
        header="Depth"
        sortable
      >
        <template #body="slotProps">
          <div class="bold">
            {{ findMinMaxFields(slotProps.data.depth) }}
          </div>
        </template>
      </Column>
      <Column
        field="owner"
        header="Date & Time"
        sortable
      >
        <template #body="slotProps">
          <div class="bold">
            {{ formatDate(slotProps.data.timestamp) }}
          </div>
        </template>
      </Column>
      <Column>
        <template #body="slotProps">
          <div 
            v-if="slotProps.data.listType === 'Swap'"
            :class="{'swap__trigger': true, 'swap__trigger--active': expandedItems[slotProps.data.id]}"
            @click="swapShowHide(slotProps.data)"
          >
            <SwapIcon />
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <SwapData
          :coin="coinData"
          :currencies="currencies"
          :input="slotProps.data.inputAmounts"
          :output="slotProps.data.outputAmounts"
        />
      </template>
      <template #empty>
        Sources not found
      </template>
    </DataTable>
  </div>
</template>

<script>
// Components
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import GlPopoverHint from '@/components/gl-popover-hint.vue'
import BadgeInfo from '@/pages/report/components/redesign/risk-score-components/BadgeInfo'
import SwapIcon from '@/pages/report/components/redesign/sof-components/svg/SwapDropdownIcon'
import SwapData from '@/pages/report/components/redesign/sof-components/SwapData'
// Utils
import { formatAmount } from '@/utils/format-amount'
import {
  formatShare,
  roundShare,
  formatter,
  formatterDepthSortValue,
} from '@/utils/sourcesFormatter'
import { findMinMaxFields } from '@/utils/model'
import { checkMultiple } from '@/utils/report-data-formatter'
import { toClipBoard } from '@/utils/helpers'
import { trancateString } from '@/utils/text-formatter'
import { toComaSeparate } from '@/utils/formatNumber'
import { linkDataFormatter, windowOpenLink } from '@/utils/go-to-route'
import { formatByPrice, priceMessage } from '@/utils/format-by-price'
import { scrollToSelector } from '@/utils/scroll-to'
// Vuex
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'SourceTable',
  components: {
    DataTable,
    Column,
    GlPopoverHint,
    BadgeInfo,
    SwapIcon,
    SwapData,
  },
  props: {
    activeSourceList: {
      type: Array,
      default: () => [],
    },
    currencies: {
      type: Object,
      default: () => {},
    },
    numberOfTxs: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: 'sof',
    }
  },
  data() {
    return {
      perPage: 10,
      hoveredPriceTooltipIndex: null,
      hoveredTooltipRoot: false,
      hoveredTooltipLeaf: false,
      hoveredHeadPriceTooltip: false,
      sourceTableContainer: null,
      expandedItems: {},
      expandedRows: [],
    }
  },
  computed: {
    ...mapState('analytics', ['coinData', 'coinType']),
    ...mapGetters('report', ['getActiveType', 'getActiveFunds']),
    activeSourceListData() {
      return this.activeSourceList.map((source, index) => ({
        ...source,
        id: index,
        depthSortValue: this.formatterDepthSortValue(source.depth),
      }))
    },
    activeSourcesFormatter() {
      return this.sumValues(this.formatter(this.activeSourceList))
    },
    headerHeight() {
      return getComputedStyle(document.documentElement).getPropertyValue(
        '--header-height',
      )
    },
    dataForBalance() {
      return this.formatAmount(this.activeSourcesFormatter.amount)
    },
    numberOfExposureShare() {
      return this.activeSourceListData.reduce((sum, item) => {
        return sum + (this.getActiveFunds === 'full' ? (item.fullExposureShare || 0) : (item.share || 0));
      }, 0);
    }
  },
  mounted() {
    this.sourceTableContainer = this.$refs.sourceTableContainer
  },
  methods: {
    formatAmount,
    formatByPrice,
    priceMessage,
    formatShare,
    roundShare,
    findMinMaxFields,
    checkMultiple,
    toClipBoard,
    formatter,
    trancateString,
    toComaSeparate,
    formatterDepthSortValue,
    linkDataFormatter,
    windowOpenLink,
    scrollToSelector,
    scrollToSelectorItem(wrap, aim) {
      this.scrollToSelector(wrap, aim, parseInt(this.headerHeight))
    },
    onPage() {
      this.scrollToSelectorItem('.main', `.source-table-${this.type}`, 200)
    },
    openLink(value, coin, type) {
      this.windowOpenLink(this.linkDataFormatter(value, coin, type)?.link)
    },
    formatDate(timestamp) {
      if (typeof timestamp === 'object' && timestamp !== null && 'minimum' in timestamp) {
        timestamp = timestamp.minimum || timestamp.maximum
      }

      const date = new Date(timestamp * 1000)

      const addLeadingZero = (num) => (num < 10 ? '0' : '') + num

      const day = addLeadingZero(date.getDate())
      const month = addLeadingZero(date.getMonth() + 1)
      const year = date.getFullYear()
      const hours = addLeadingZero(date.getHours())
      const minutes = addLeadingZero(date.getMinutes())

      const formattedDate = `${day}.${month}.${year} ${hours}:${minutes}`

      return formattedDate
    },
    swapShowHide(row) {
      const openRow = this.expandedRows.findIndex(item => item.id == row.id)
      if(openRow >= 0) {
        this.expandedRows.splice(openRow, 1)
      } else {
        this.expandedRows.push(row)
      }
      this.$set(this.expandedItems, row.id, !this.expandedItems[row.id])
    },
    sumValues(arr) {
      return arr.reduce((acc, obj) => {
        acc.share += obj.share || 0
        acc.amount += obj.amount || 0
        acc.amountCur += obj.amountCur || 0
        acc.fullExposureShare += obj.fullExposureShare || 0
        return acc
      }, { share: 0, amount: 0, amountCur: 0, fullExposureShare: 0 })
    },
  },
}
</script>

<style>
.source-table-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 300px;
}
.source-table-head-wrapper {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  margin-bottom: 16px;
  white-space: nowrap;
  overflow-x: auto;
}

.source-table-wrapper .p-datatable {
  font-size: 12px;
}

.source-table-wrapper
  .p-datatable-responsive-scroll
  > .p-datatable-wrapper::-webkit-scrollbar,
.source-table-head-wrapper::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

.source-table-header-info {
  display: flex;
  justify-content: end;
  order: 1;
  position: relative;
}

.source-table-popover-content-wrapper {
  width: 470px;
  max-width: 70vw;
}

.source-table-popover-img-wrapper img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

/* swaps */
.swap__trigger {
  padding: 4px;
  user-select: none;
  cursor: pointer;
}
.swap__trigger svg {
  display: block;
  transition: transform 0.3s ease;
}
.swap__trigger--active svg {
  transform: rotate(180deg);
}
</style>
