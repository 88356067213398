var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swap__data"},[_c('div',{staticClass:"swap__data-deposit"},[_c('div',{staticClass:"swap__data-title"},[_vm._v(" Deposit ")]),_c('div',{staticClass:"swap__data-amount"},[_vm._v(" "+_vm._s(_vm.depositData(_vm.input))+" ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: ("" + (_vm.priceMessage(
          'historical',
          _vm.inputAmountCurTime
        ))),
        show: _vm.inputPriceFeedTooltip,
        trigger: 'manual',
      }),expression:"{\n        content: `${priceMessage(\n          'historical',\n          inputAmountCurTime,\n        )}`,\n        show: inputPriceFeedTooltip,\n        trigger: 'manual',\n      }"}],staticClass:"price fs-10",on:{"mouseleave":function($event){_vm.inputPriceFeedTooltip = false},"mouseover":function($event){_vm.inputPriceFeedTooltip = true}}},[_vm._v(" "+_vm._s(_vm.formatByPrice(_vm.inputAmountCur))+" ")])]),_c('div',{staticClass:"swap__data-withdraw"},[_c('div',{staticClass:"swap__data-title"},[_vm._v(" Withdraw ")]),_c('div',{staticClass:"swap__data-amount"},[_vm._v(" "+_vm._s(_vm.withdrawData(_vm.output))+" ")]),_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: ("" + (_vm.priceMessage(
          'historical',
          _vm.outputAmountCurTime
        ))),
        show: _vm.outputPriceFeedTooltip,
        trigger: 'manual',
      }),expression:"{\n        content: `${priceMessage(\n          'historical',\n          outputAmountCurTime,\n        )}`,\n        show: outputPriceFeedTooltip,\n        trigger: 'manual',\n      }"}],staticClass:"price fs-10",on:{"mouseleave":function($event){_vm.outputPriceFeedTooltip = false},"mouseover":function($event){_vm.outputPriceFeedTooltip = true}}},[_vm._v(" "+_vm._s(_vm.formatByPrice(_vm.outputAmountCur))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }