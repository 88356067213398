<template>
  <div class="swap__data">
    <div class="swap__data-deposit">
      <div class="swap__data-title">
        Deposit
      </div>
      <div class="swap__data-amount">
        {{ depositData(input) }}
      </div>
      <div 
        v-tooltip="{
          content: `${priceMessage(
            'historical',
            inputAmountCurTime,
          )}`,
          show: inputPriceFeedTooltip,
          trigger: 'manual',
        }"
        class="price fs-10"
        @mouseleave="inputPriceFeedTooltip = false"
        @mouseover="inputPriceFeedTooltip = true"
      >
        {{ formatByPrice(inputAmountCur) }}
      </div>
    </div>
    <div class="swap__data-withdraw">
      <div class="swap__data-title">
        Withdraw
      </div>
      <div class="swap__data-amount">
        {{ withdrawData(output) }}
      </div>
      <div 
        v-tooltip="{
          content: `${priceMessage(
            'historical',
            outputAmountCurTime,
          )}`,
          show: outputPriceFeedTooltip,
          trigger: 'manual',
        }"
        class="price fs-10"
        @mouseleave="outputPriceFeedTooltip = false"
        @mouseover="outputPriceFeedTooltip = true"
      >
        {{ formatByPrice(outputAmountCur) }}
      </div>
    </div>
  </div>
</template>

<script>
import { formatAmount } from '@/utils/format-amount'
import { formatByPrice, priceMessage } from '@/utils/format-by-price'
import { toComaSeparate } from '@/utils/formatNumber'

export default {
  props: {
    currencies: {
      type: Object,
      default: () => {},
    },
    input: {
      type: Array,
      default: () => [],
    },
    output: {
      type: Array,
      default: () => [],
    },
    coin: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      inputAmountCur: null,
      inputAmountCurTime: null,
      inputPriceFeedTooltip: false,
      outputAmountCur: null,
      outputAmountCurTime: null,
      outputPriceFeedTooltip: false,
    }
  },
  methods: {
    formatAmount,
    formatByPrice,
    priceMessage,
    toComaSeparate,
    depositData(data) {
      const {choosenObj, value} = this.dataLogic(data)
      this.inputAmountCur = choosenObj.amountCur
      this.inputAmountCurTime = choosenObj.amountCurTimestamp
      return value
    },
    withdrawData(data) {
      const {choosenObj, value} = this.dataLogic(data)
      this.outputAmountCur = choosenObj.amountCur
      this.outputAmountCurTime = choosenObj.amountCurTimestamp
      return value
    },
    dataLogic(data) {
      let objWithCurrency = data.find(item => 'currency' in item)

      if (objWithCurrency) {
        return {
          choosenObj: objWithCurrency,
          value: this.formatAmount(objWithCurrency.amount, {
            amountCoin: this.currencies[objWithCurrency.currency].currency,
            amountDecimals: this.currencies[objWithCurrency.currency].decimals,
            comaSeparate: true,
          })
        }
      } else {
        return {
          choosenObj: data[0],
          value: this.formatAmount(data[0].amount, {
            amountCoin: this.coin.family,
            amountDecimals: this.coin.decimals,
            comaSeparate: true,
          })
        }
      }
    },
  }
}
</script>

<style>
.swap__data {
  display: flex;
  column-gap: 80px;
  padding: 8px 0;
}
.swap__data-deposit {
  flex: 0 0 25%;
}
.swap__data-title {
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 1;
}
.swap__data-amount {
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
}
.swap__data-deposit .price {
  display: inline-block;
}
</style>