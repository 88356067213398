<template>
  <div class="report-full-tx-section-wrapper">
    <div :class="classesForWrapper">
      <SourceTable
        :active-source-list="filteredSources"
        :currencies="currencies"
        :number-of-txs="numberOfTxs"
        :type="type"
      />
    </div>
  </div>
</template>

<script>
// Components
import SourceTable from '@/pages/report/components/table-components/SourceTable'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    SourceTable,
  },
  props: {
    transactionsPart: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveFunds', 'getActiveType', 'getActiveRowName']),
    filteredSources() {
      if (this.getActiveRowName) {
        if (this.getActiveType == 'owners') {
          return this.transactionsPart?.data?.sources.filter(item => item.owner === this.getActiveRowName)
        } else {
          return this.transactionsPart?.data?.sources.filter(item => item.funds.type === this.getActiveRowName)
        }
      } else {
        return this.transactionsPart?.data?.sources
      }
    },
    currencies() {
      return this.transactionsPart?.data?.currencies
    },
    numberOfTxs() {
      const uniqueValues = new Set()

      for (const key in this.filteredSources) {
        if (Object.prototype.hasOwnProperty.call(this.filteredSources, key)) {
          const item = this.filteredSources[key]
          if (Object.prototype.hasOwnProperty.call(item, 'directTx')) {
            uniqueValues.add(item['directTx'])
          }
        }
      }

      return uniqueValues.size
    },
    classesForWrapper() {
      return `report-full-tx-table-wrapper source-table-${this.type}`;
    },
  },
  watch: {
    filteredSources: {
      handler(newFilteredSources) {
        if (this.type === 'sof') {
          this.SET_SOF_TRANSACTIONS(newFilteredSources || [])
        } else if (this.type === 'uof') {
          this.SET_UOF_TRANSACTIONS(newFilteredSources || [])
        }
      },
      immediate: true,
    },
    currencies: {
      handler(newCurrencies) {
        if (this.type === 'sof') {
          this.SET_SOF_CURRENCIES(newCurrencies || {})
        } else if (this.type === 'uof') {
          this.SET_UOF_CURRENCIES(newCurrencies || {})
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('report', ['SET_SOF_TRANSACTIONS', 'SET_UOF_TRANSACTIONS', 'SET_SOF_CURRENCIES', 'SET_UOF_CURRENCIES']),
  },
}
</script>

<style>
.report-full-tx-hint-img-wrapper {
  margin-top: 24px;
  margin-bottom: 8px;
}
.report-full-tx-hint-img-wrapper img {
  object-fit: contain;
  height: 100%;
  width: 100%;
}

.report-full-tx-hint-text-wrapper {
  color: var(--light-grey-59);
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 125%;
  max-width: 250px;
  margin-bottom: 24px;
}

/* slide-source */
.slide-source-enter-active {
  transition: all 0.4s linear;
}

.slide-source-leave-active {
  transition: all 0.4s linear;
}

.slide-source-enter,
.slide-source-leave-to {
  opacity: 0;
}

.slide-source-table-enter-active {
  transition: all 0.3s linear;
}

.slide-source-table-leave-active {
  transition: all 0s linear;
}

.slide-source-table-enter,
.slide-source-table-leave-to {
  height: 0;
}
</style>
