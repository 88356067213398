<template>
  <div class="risk-exposure-cntrl">
    <div class="risk-exposure-cntrl__left">
      <GlSelectButton
        v-model="typeState"
        :options="typePartOptions"
        @input="typeChange()"
      />
    </div>
    <div class="risk-exposure-cntrl__center">
      <GlSelectButton
        v-model="fundsState"
        :options="fundsPartOptions"
        @input="fundsChange()"
      />
    </div>
    <div class="risk-exposure-cntrl__right">
      <DropDownSort @sortChange="sortChange" />
    </div>
  </div>
</template>

<script>
// vuex
import { mapMutations, mapGetters } from 'vuex'

// components
import GlSelectButton from '@/components/gl-select-button'
import DropDownSort from '@/pages/report/components/redesign/risk-exposure-components/DropDownSort'

export default {
  components: {
    GlSelectButton,
    DropDownSort,
  },
  data() {
    return {
      typeState: null,
      fundsState: null,
      typePartOptions: [
        { name: 'Risk Labels', value: 'riskLabels' },
        { name: 'Owners', value: 'owners' },
      ],
      fundsPartOptions: [
        { name: 'Full Exposure', value: 'full' },
        { name: 'Source of Funds', value: 'sof' },
        { name: 'Use of Funds', value: 'uof' },
      ],
    }
  },
  computed: {
    ...mapGetters('report', ['getActiveType', 'getActiveFunds']),
  },
  mounted() {
    this.typeState = this.typePartOptions.find(item => item.value == this.getActiveType)
    this.fundsState = this.fundsPartOptions.find(item => item.value == this.getActiveFunds)
  },
  methods: {
    ...mapMutations({
      SET_ACTIVE_TYPE: 'report/SET_ACTIVE_TYPE',
      SET_ACTIVE_FUNDS: 'report/SET_ACTIVE_FUNDS',
      SET_ACTIVE_SORT: 'report/SET_ACTIVE_SORT',
    }),
    typeChange() {
      this.SET_ACTIVE_TYPE(this.typeState.value)
    },
    fundsChange() {
      this.SET_ACTIVE_FUNDS(this.fundsState.value)
    },
    sortChange(value) {
      this.SET_ACTIVE_SORT(value)
    },
  },
}
</script>

<style scoped>
.risk-exposure-cntrl {
  display: flex;
}
.risk-exposure-cntrl__left,
.risk-exposure-cntrl__right {
  flex: 0 0 220px;
}
.risk-exposure-cntrl__center {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
}
.risk-exposure-cntrl__center .p-selectbutton {
  width: 440px;
}
</style>