<template>
  <div :class="{ 'flex flex-items-baseline gap-3': inlineView }">
    <div
      class="info-block-key mr-2"
      :class="{ 'info-block-key--inline': inlineView }"
      :style="{ 'font-size': fontSize }"
    >
      {{ label }}
    </div>
    <div
      class="info-block-value flex align-center"
      :class="{
        'info-block-value--inline': inlineView,
        'flex-2': flexSecondColumnTwo,
      }"
    >
      <div
        v-if="!loading && $slots.icon"
        class="mr-2"
      >
        <slot name="icon" />
      </div>
      <VueSkeletonLoader
        v-if="loading"
        animation="wave"
        :color="'#bec3d8'"
        :height="20"
        :radius="'2'"
        type="rect"
      />
      <div
        v-else
        class="mr-2"
        :class="{ 'word-break': wordBreakValue, 'link-text': linkTextValue }"
        @click="$emit('clickedValue')"
      >
        {{ value === 0 ? 0 : (value || '–') }}
        <div
          v-if="rate"
          v-on-clickaway="closeTooltip"
          v-tooltip="{
            content: `${priceMessage(rateTime, rateTimestamp)}`,
            show: showTooltip,
            trigger: 'manual',
          }"
          class="price info-block-rate"
          @mouseleave="showTooltip = false"
          @mouseover="showTooltip = true"
        >
          {{ rate }}
        </div>
      </div>
      <div v-if="withCopy && value">
        <gl-menu-item
          class="sidebar__history-copy"
          icon="copy"
          :icon-height="24"
          :icon-width="24"
          @click="copy(value)"
        />
      </div>
    </div>
  </div>
</template>

<script>
// Components
import VueSkeletonLoader from 'skeleton-loader-vue'
import GlMenuItem from '@/components/gl-menu-item'
import GlIcon from '@/components/gl-icon'
// Utils
import { priceMessage } from '@/utils/format-by-price'
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'InfoBlock',
  components: {
    VueSkeletonLoader,
    GlMenuItem,
    GlIcon,
  },
  mixins: [clickaway],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    withCopy: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    inlineView: {
      type: Boolean,
      default: false,
    },
    flexSecondColumnTwo: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    rate: {
      type: [String, Number],
      default: '',
    },
    rateTime: {
      type: [String],
      default: '',
    },
    rateTimestamp: {
      type: [String, Number],
      default: null,
    },
    fontSize: {
      type: [String],
      default: '',
    },
    wordBreakValue: {
      type: Boolean,
      default: false,
    },
    linkTextValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { showTooltip: false }
  },
  methods: {
    priceMessage,
    async copy(value) {
      await navigator.clipboard.writeText(value).then(() => {
        this.$toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Copied!',
          life: 3000,
        })
      })
    },
    closeTooltip() {
      this.showTooltip = false
    },
  },
}
</script>

<style>
.info-block-key {
  font-size: 18px;
  font-weight: 500;
  color: var(--light-grey-59);
  white-space: nowrap;
  word-break: break-all;
}
.info-block-value {
  font-weight: 500;
  color: var(--dark);
  word-break: break-all;
}
.info-block-key--inline {
  flex: 1;
}
.info-block-value--inline {
  flex: 1;
}
.info-block-rate {
  margin-top: 4px;
  font-size: 13px;
}
.info-block-rate-icon {
  transform: translate(0px, 1px);
}

@media (max-width: 767px) {
  .info-block-key {
    font-size: 14px;
  }
  .info-block-value {
    font-size: 14px;
  }

  .info-block-key--inline {
    flex: 0;
    min-width: 130px;
  }
}
</style>
