<template>
  <GlPageWrap>
    <div class="report-wrapper">
      <div class="report-footer-wrapper">
        <div class="report-footer">
          <GlButtonPrime
            class="height-44 min-w-250 bold-600 m-height-32 m-fullwidth m-fs-14"
            title="Export to CSV"
            @click="generateDataForReport()"
          />
          <GlButtonPrime
            class="height-44 min-w-250 bold-600 m-height-32 m-fullwidth m-fs-14"
            :loading="recalculateReportLoading"
            outlined
            title="Reload report"
            @click="initQuery(true)"
          />
        </div>
      </div>

      <div class="report">
        <div
          class="report-back-arrow-wrapper bold accent-text-link"
          @click="$router.push({ name: 'source-of-funds' })"
        >
          <gl-icon
            class="mr-2"
            :height="18"
            name="arrow-back"
            :width="11"
          />
          <div> Back to <span class="bold-700">Source of Funds</span> </div>
        </div>
        <div class="flex gap-4 m-column m-fs-14 report__header">
          <div class="flex-1 bold-700 pr-4">
            <div class="mb-2">
              {{ isAddressReport || isEthAddressReport ? 'Address ' : 'Tx' }}
              Report
            </div>
            <div class="word-break bold-600">
              {{ searchValue }}
            </div>
          </div>
          <div class="flex-1 flex gap-3">
            <div class="flex-1">
              <div class="mb-2 grey-text-6-e">
                Blockchain
              </div>
              <div class="bold uppercase">
                {{ coinData.key }}
              </div>
            </div>
            <div class="flex-1 flex column align-end">
              <div class="white-space-nowrap mb-2 grey-text-6-e">
                Report Generated At
              </div>
              <div>
                {{
                  reportGeneratedAt
                    ? formatDate(
                      new Date(reportGeneratedAt),
                      'dd.MM.yyyy HH:mm',
                    )
                    : '–'
                }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <AddressReport
            v-if="isAddressReport"
            :address-data="addressInfo"
            :address-known-sources="addressKnownSources"
            :address-part="addressPart"
            :address-risky-sources="addressRiskySources"
            :address-unknown-sources="addressUnknownSources"
            :all-data-source="allDataSource"
            :all-data-source-by-owner="allDataSourceByOwner"
            :current-hovered-part="currentHoveredPart"
            :full-details-part="fullDetailsPart"
            :has-only-one-source="hasOnlyOneSource"
            :owner-part="ownerPart"
            :percent="sourcesRiskPercent"
            :report-parts-loading="reportPartsLoading"
            :score-part="scorePart"
            :sof-entity-part="sofEntityPart"
            :sof-transactions-part="sofTransactionsPart"
            :sof-types-part="sofTypePart"
            :start-address-check-loading="startAddressCheckLoading"
            :total-funds="addressTotalFunds"
            :uof-entity-part="uofEntityPart"
            :uof-transactions-part="uofTransactionsPart"
            :uof-types-part="uofTypePart"
            @changeToken="changeToken"
            @click-unlock-now="clickUnlockNow"
            @clickNewPurchaseButton="clickUnlockNow"
            @update-hovered-part="updateHoveredPart"
          />
          <div
            v-else
            class="report-empty"
          >
            Report empty
          </div>
        </div>
      </div>
      <GlConfirmModal
        v-model="confirmPurchaseModal"
        :loading="confirmPurchaseModalLoading"
        @close="rejectModalPromise(true)"
        @confirm="resolveModalPromise(true)"
      >
        <template #header>
          <div class="bold-600 fs-24 pa-4">
            <div ref="confirmModalHeader" />
          </div>
        </template>
        <template #mainSlot>
          <div class="bold pa-4 pb-0">
            <div ref="confirmModalMainContent" />
          </div>
        </template>
      </GlConfirmModal>
    </div>
  </GlPageWrap>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
//Components
import GlPageWrap from '@/components/layout/gl-page-wrap'
import AddressReport from '@/pages/report/components/AddressReport'
import GlIcon from '@/components/gl-icon'
import GlButtonPrime from '@/components/gl-button-prime'
import GlConfirmModal from '@/components/gl-confirm-modal'
// Utils
import { csvExportReport } from '@/utils/export-data'
import { getObjectByKey } from '@/utils/helpers'
import { formatShare, formatter } from '@/utils/sourcesFormatter'
import { findColorByTypeScore } from '@/utils/cytoskape-ui-rules'
import { tokensSorting } from '@/utils/cytoskape-ui-rules'
import { formatDate } from '@/utils/format-date'
import config from '@/utils/appConfig'
//mixins
import confirmModalMixin from '@/assets/mixins/confirmModalMixin'
import csvExport from '@/assets/mixins/csvExport'

export default {
  components: {
    GlPageWrap,
    AddressReport,
    GlIcon,
    GlButtonPrime,
    GlConfirmModal,
  },
  mixins: [confirmModalMixin, csvExport],
  data() {
    return {
      search: '',
      confirmPurchaseModal: false,
      confirmPurchaseModalLoading: false,
      recalculateReportLoading: false,

      addressPart: {},
      ownerPart: {},
      scorePart: {},
      sofTypePart: {},
      sofEntityPart: {},
      sofTransactionsPart: {},
      uofTypePart: {},
      uofEntityPart: {},
      uofTransactionsPart: {},
      fullDetailsPart: {},
      currentHoveredPart: {},
      reportGeneratedAt: '',

      addressInfo: {},
      addressData: {},
      txInfo: {},
      isAddressReport: false,
      isEthAddressReport: false,
      isTxReport: false,
      startAddressCheckLoading: false,
      reportPartsLoading: false,
      allSources: [],
      allDataSource: [],
      allDataSourceByOwner: [],
      allDataSourceByOwnerType: [],
      addressRiskySources: [],
      addressUnknownSources: [],
      addressKnownSources: [],
      txRiskySources: [],
      txUnknownSources: [],
      txKnownSources: [],
      messages: [],
      tokensList: [],
      contractData: {},
      sourcesRiskPercent: 0,
      txRiskPercent: 0,
      addressTotalFunds: 0,
      txTotalFunds: 0,
      totalAmount: 0,
      sourcesCount: 0,
      symbol: '',
      selectedToken: {
        symbol: 'ETH',
        address: '',
      },
      searchValue: '',
    }
  },
  computed: {
    ...mapState('user', ['userData']),
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
    ...mapGetters('report', ['getSofTransactions', 'getUofTransactions']),
    hasOnlyOneSource() {
      return this.sourcesCount === 1
    },
    enableGeneratePdf() {
      return config.VUE_APP_PDF_GENERATOR
    },
    hasReportData() {
      return (
        Object.keys(this.addressInfo).length !== 0 ||
        Object.keys(this.txInfo).length !== 0 ||
        this.allDataSource.length > 0
      )
    },
    addressReport() {
      return this.isAddressReport || this.isEthAddressReport
    },
  },
  async mounted() {
    await this.getMe()
    this.initQuery()
  },
  methods: {
    ...mapMutations('analytics', ['SET_COIN_TYPE', 'SET_COIN_DATA']),
    ...mapMutations({
      SET_REDIRECT_PAYMENT_MODAL: 'payment/SET_REDIRECT_PAYMENT_MODAL',
    }),
    ...mapMutations({
      SET_REPORT_INFO: 'report/SET_REPORT_INFO',
      SET_ACTIVE_TOKEN: 'report/SET_ACTIVE_TOKEN',
      SET_PURCHASED_INFO: 'report/SET_PURCHASED_INFO',
      SET_CURRENT_PRICES: 'report/SET_CURRENT_PRICES',
    }),
    ...mapActions('report', [
      'getStartAddressCheck',
      'getReportParts',
      'purchaseReports',
    ]),
    ...mapActions('user', ['getMe']),
    formatter,
    formatShare,
    tokensSorting,
    csvExportReport,
    findColorByTypeScore,
    getObjectByKey,
    formatDate,
    clickUnlockNow(currentPart) {
      if (!currentPart.price && currentPart.price != 0) {
        return
      }
      if (this.userData.balance < currentPart.price) {
        this.SET_REDIRECT_PAYMENT_MODAL(true)
        return
      }
      this.confirmPurchaseModal = true
      this.$nextTick(() => {
        if (currentPart.reportPartName === 'full') {
          this.$refs.confirmModalHeader.outerHTML = '<p>Unlock Full Report?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the full report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'owner') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Owner Information?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Owner Information report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'score') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Risk Score Information?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Risk Score Information report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'sof_type') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Source of Funds by types?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds by Type report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'sof_entity') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Source of Funds by entity?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds by Entities report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'sof_transactions') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Source of Funds transactions?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds Transactions report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'uof_type') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Use of Funds by types?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds by Type report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'uof_entity') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Use of Funds by entities?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds by Entities report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
        if (currentPart.reportPartName === 'uof_transactions') {
          this.$refs.confirmModalHeader.outerHTML =
            '<p>Unlock Use of Funds transactions?</p>'
          this.$refs.confirmModalMainContent.outerHTML = `<p>Are you sure you want to purchase the Source of Funds Transactions report for <b>${currentPart.price}</b> Lookup(s)?</p>`
        }
      })
      this.confirmModalPromice()
        .then(e => {
          if (!e) return
          this.purchaseConfirmed(currentPart)
        })
        .catch(e => {
          if (!e) return
          this.confirmPurchaseModal = false
        })
    },
    updateHoveredPart(part) {
      this.currentHoveredPart = part
    },
    initQuery(reloadReport) {
      const { query } = this.$route

      if (query.type) {
        this.SET_COIN_TYPE(query.type)
        const coin = this.currencyList.find(curr => curr.key === query.type)
        this.SET_COIN_DATA(coin)
        this.selectedToken.symbol = this.coinData.label
      }

      if (query.token) {
        this.selectedToken.address = query.token
      }

      if (query.address) {
        this.search = query.address
        this.searchData(query.address, reloadReport)
        return
      }

      if (query.tx) {
        this.search = query.tx
        this.searchData(query.tx)
      }
    },
    purchaseConfirmed({ reportPartName }) {
      this.confirmPurchaseModalLoading = true
      this.purchaseReports({
        address: this.search,
        coin: this.coinData.key,
        reportPartName: reportPartName,
      })
        .then(e => {
          if (!e?.success) {
            this.$toast.add({
              severity: 'error',
              summary: 'Error Message',
              detail: e?.data?.message,
              life: 3000,
            })
            return
          }
        })
        .finally(() => {
          this.getMe()
          this.initQuery()
          this.confirmPurchaseModalLoading = false
          this.confirmPurchaseModal = false
        })
    },
    async searchData(value, reloadReport) {
      if (this.searchValue && this.searchValue !== value) {
        this.selectedToken = {
          symbol: this.coinData.label,
          address: '',
        }
      }

      this.searchValue = value
      this.clearData()
      await this.SET_COIN_TYPE(this.coinType)

      if (
        this.$can('use', this.coinData.family) &&
        new RegExp(this.coinData.addressRegex).test(value)
      ) {
        this.isAddressReport = true
        this.startAddressCheckLoading = true
        this.reportPartsLoading = true

        await this.getStartAddressCheck({
          address: value,
          coin: this.coinData.key,
        })
          .then(({ data, success }) => {
            this.takePurchasedInfo(data)
            this.takeCurrentPrices(data)
            if (!success) {
              this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: data,
                life: 3000,
              })
              return
            }
            this.addressPart = {
              ...this.getObjectByKey(data, 'address', 'reportPartName'),
            }
            this.ownerPart = {
              ...this.getObjectByKey(data, 'owner', 'reportPartName'),
            }
            this.scorePart = {
              ...this.getObjectByKey(data, 'score', 'reportPartName'),
            }
            this.sofTypePart = {
              ...this.getObjectByKey(data, 'sof_type', 'reportPartName'),
            }
            this.sofEntityPart = {
              ...this.getObjectByKey(data, 'sof_entity', 'reportPartName'),
            }
            this.sofTransactionsPart = {
              ...this.getObjectByKey(data, 'sof_transactions', 'reportPartName'),
            }
            this.uofTypePart = {
              ...this.getObjectByKey(data, 'uof_type', 'reportPartName'),
            }
            this.uofEntityPart = {
              ...this.getObjectByKey(data, 'uof_entity', 'reportPartName'),
            }
            this.uofTransactionsPart = {
              ...this.getObjectByKey(data, 'uof_transactions', 'reportPartName'),
            }
            this.fullDetailsPart = {
              ...this.getObjectByKey(data, 'full', 'reportPartName'),
            }
          })
          .catch(() => {
            this.reportPartsLoading = false
          })
          .finally(() => {
            this.startAddressCheckLoading = false
          })

        const params = {
          address: value,
          coin: this.coinData.key,
          ...(reloadReport && { recalculate: true }),
          ...(this.coinData.supportContracts ? { token: this.selectedToken.address } : {})
        }

        await this.getReportParts(params)
          .then(({ success, data }) => {
            if (!success) {
              this.$toast.add({
                severity: 'error',
                summary: 'Error Message',
                detail: data.message,
                life: 3000,
              })
              return
            }
            const { parts, createdAt } = data
            this.addressPart = {
              ...this.addressPart,
              ...this.getObjectByKey(parts, 'address', 'type'),
            }
            this.ownerPart = {
              ...this.ownerPart,
              ...this.getObjectByKey(parts, 'owner', 'type'),
            }
            this.scorePart = {
              ...this.scorePart,
              ...this.getObjectByKey(parts, 'score', 'type'),
            }
            this.sofTypePart = {
              ...this.sofTypePart,
              ...this.getObjectByKey(parts, 'sof_type', 'type'),
            }
            this.sofEntityPart = {
              ...this.sofEntityPart,
              ...this.getObjectByKey(parts, 'sof_entity', 'type'),
            }
            this.sofTransactionsPart = {
              ...this.sofTransactionsPart,
              ...this.getObjectByKey(parts, 'sof_transactions', 'type'),
            }
            this.uofTypePart = {
              ...this.uofTypePart,
              ...this.getObjectByKey(parts, 'uof_type', 'type'),
            }
            this.uofEntityPart = {
              ...this.uofEntityPart,
              ...this.getObjectByKey(parts, 'uof_entity', 'type'),
            }
            this.uofTransactionsPart = {
              ...this.uofTransactionsPart,
              ...this.getObjectByKey(parts, 'uof_transactions', 'type'),
            }
            
            this.SET_ACTIVE_TOKEN(this.addressPart.data.tokens[this.selectedToken.address] || this.addressPart.data.tokens[this.coinData.symbol])

            const reportInfo = {
              createdAt,
              walletAddress: this.searchValue,
              owner: this.ownerPart?.data?.owner,
              ownerType: this.ownerPart?.data?.type,
              ownerTags: this.ownerPart?.data?.tags,
              balance: this.addressPart?.data?.balance,
              totalTxReceived: this.addressPart?.data?.totalTxReceived,
              totalTxSent: this.addressPart?.data?.totalTxSent,
              numberOfTxs: this.addressPart?.data?.numberOfTxs,
              totalReceived: this.addressPart?.data?.totalReceived,
              totalReceivedPrice: this.addressPart?.data?.totalReceivedPrice,
              totalSent: this.addressPart?.data?.totalSent,
              totalSentPrice: this.addressPart?.data?.totalSentPrice,
              firstSeen: this.addressPart?.data?.firstSeen,
              lastSeen: this.addressPart?.data?.lastSeen,
              riskScore: this.scorePart?.data?.totalScore,
              tags: this.ownerPart?.data?.type?.name
            }

            this.SET_REPORT_INFO(reportInfo)

            this.reportGeneratedAt = createdAt
          })
          .finally(() => {
            this.reportPartsLoading = false
          })

        
        if (this.$route.name === 'report') {
          const { params } = this.$route

          const replaceParams = {
            address: value, 
            type: this.coinType,
            ...(this.coinData.supportContracts ? { token: this.selectedToken.address } : {})
          }

          await this.$router
            .replace({
              name: 'report',
              query: replaceParams,
              params,
            })
            .catch(err => err)
        }
        return
      }
      this.$toast.add({
        severity: 'error',
        summary: 'Error Message',
        detail: 'Search value is not valid',
        life: 3000,
      })
    },
    formattingScoringList(data) {
      let SCORING_LIST = []

      if (data.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.tags]
      }

      if (data.clusterData && data.clusterData.tags) {
        SCORING_LIST = [...SCORING_LIST, ...data.clusterData.tags]
      }

      if (data.type) {
        SCORING_LIST = [...SCORING_LIST, data.type]
      }

      if (data.clusterData && data.clusterData.type) {
        SCORING_LIST = [...SCORING_LIST, data.clusterData.type]
      }

      SCORING_LIST = SCORING_LIST.filter(
        (v, i, a) => a.findIndex(t => t.name === v.name) === i,
      )

      SCORING_LIST.sort((a, b) => (a.score < b.score ? 1 : -1))

      return SCORING_LIST
    },
    clearData() {
      this.addressPart = {}
      this.ownerPart = {}
      this.scorePart = {}
      this.sofTypePart = {}
      this.sofEntityPart = {}
      this.uofTypePart = {}
      this.uofEntityPart = {}
      this.fullDetailsPart = {}
      this.currentPartDataForConfirm = {}
      this.reportGeneratedAt = ''

      this.isAddressReport = false
      this.isTxReport = false

      this.addressData = null
      this.addressInfo = {}
      this.swapSources = []
      this.allSources = []
      this.allDataSource = []
      this.allDataSourceByOwner = []
      this.allDataSourceByOwnerType = []
      this.addressRiskySources = []
      this.addressUnknownSources = []
      this.addressKnownSources = []
      this.txRiskySources = []
      this.txUnknownSources = []
      this.txKnownSources = []

      this.sourcesRiskPercent = 0
      this.txRiskPercent = 0
      this.addressTotalFunds = 0
      this.txTotalFunds = 0
      this.sourcesCount = 0
    },
    async changeToken(token) {
      const { query } = this.$route

      this.selectedToken.address = token.address

      await this.$router
        .replace({
          name: 'report',
          query: { ...query, token: token.address },
        })
      
      this.initQuery()
    },
    takePurchasedInfo(array) {
      const purchasedInfo = array.reduce((acc, item) => {
          acc[item.reportPartName] = item.purchased
          return acc
      }, {})
      this.SET_PURCHASED_INFO(purchasedInfo)
    },
    takeCurrentPrices(array) {
      const currentPrices = array.reduce((acc, item) => {
          acc[item.reportPartName] = item.price
          return acc
      }, {})
      this.SET_CURRENT_PRICES(currentPrices)
    },
  },
}
</script>

<style>
:root {
  --report-footer-height: 68px;
}
.report-wrapper {
  display: flex;
  justify-content: center;
  padding-top: var(--header-height);
  width: 100%;
  min-height: var(--main-content-height);
  padding-bottom: calc(
    var(--report-footer-height) + var(--report-banner-height) + 10px
  );
  overflow: hidden;
}
.report {
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}

.report-back-arrow-wrapper {
  display: inline-flex;
  align-items: center;
  margin-top: 28px;
  margin-bottom: 30px;
  cursor: pointer;
  user-select: none;
}

.report .info-block-key {
  max-width: 200px;
}

.report__header {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--pale-blue);
}

.report-section__content-wrapper {
  display: flex;
  gap: 24px;
}
.report-block-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

@media screen and (max-width: 650px) {
  .report-section__content-wrapper {
    flex-direction: column;
  }
}

.report-footer-wrapper {
  position: fixed;
  left: 0;
  bottom: var(--report-banner-height);
  width: var(--full-width);
  height: var(--report-footer-height);
  display: flex;
  justify-content: center;
  background: var(--white);
  z-index: 99;
  box-shadow: 0 0 8px 0 hsla(0, 0%, 43.1%, 0.25098);
}

.report-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  width: var(--main-content-width);
  max-width: var(--main-content-max-width);
}

.report-block__header {
  align-items: center;
  display: flex;
  font-size: 24px;
  padding: 14px;
  font-weight: 600;
  border: 1px solid #fff;
  border-bottom: 0;
  border-radius: 10px 10px 0px 0px;
}

.circle-progress svg {
  width: 100%;
}

/*.circle-progress svg path {*/
/*  stroke-width: px;*/
/*}*/

.a-circle-progress-wrapper .circle-progress {
  flex-direction: column;
}

.a-circle-progress-wrapper
  .circle-progress
  .progress-content
  .inner-default-percentage {
  align-items: flex-end;
}

.report-block__header--sof {
  background: rgba(240, 242, 249, 1);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  margin: 12px 0;
}

@media (max-width: 767px) {
  :root {
    --report-footer-height: 48px;
  }
  .report-back-arrow-wrapper {
    display: none;
  }
}
</style>
